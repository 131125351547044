export const calculateMonthsBetweenDates = (dateString1: any, dateString2: any) => {
  if (
    dateString1 === '' ||
    dateString2 === '' ||
    dateString1 === undefined ||
    dateString2 === undefined ||
    dateString1 === null ||
    dateString2 === null
  ) {
    return '';
  } else {
    const date1: any = new Date(dateString1);
    const date2: any = new Date(dateString2);
    const diffMs = Math.abs(date2 - date1);
    const msInMonth = 30.44 * 24 * 60 * 60 * 1000;
    const diffMonths = diffMs / msInMonth;
    // use Math.round instead of Math.floor to make sure it will round any fraction bigger than or euqal 5.
    return Math.round(diffMonths);
  }
};
export const subtractDollarValues = (value1: any, value2: any) => {
  if (
    value1 === '' ||
    value2 === '' ||
    value1 === undefined ||
    value2 === undefined ||
    value1 === null ||
    value2 === null
  ) {
    return '';
  }
  const num1 = parseFloat(value1.replace('$', ''));
  const num2 = parseFloat(value2.replace('$', ''));
  const result = num1 - num2;
  const formattedResult = '$' + result.toFixed(2);
  return formattedResult;
};

export const replacePlaceholder = (originalString: string, placeholder: string, replacement: string) => {
  // Check if any parameter is blank
  if (!originalString || !placeholder) {
    return '';
  }

  // Replace all occurrences of the placeholder with the replacement value
  return originalString.replace(placeholder, replacement);
};
export const truncateString = (str: any, maxLength: any) => {
  if (str.length > maxLength) {
    return str.slice(0, maxLength);
  } else {
    return str;
  }
};
export const getCookie = (name: string): string | null => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop()?.split(';').shift() || null;
  return null;
};
export const capitalizeFirstLetter = (str) => {
  const newStr = str?.trim() || '';
  if (newStr) {
    return str
      .split(' ')
      .map((word) => `${word.charAt(0).toUpperCase()}${word.slice(1).toLowerCase()}`)
      .join(' ');
  } else {
    return str;
  }
};
export const replaceAllPlaceholder = (originalString: string, placeholder: string, replacement: string) => {
  // Check if any parameter is blank
  if (!originalString || !placeholder) {
    return '';
  }
  // Replace all occurrences of the placeholder with the replacement value
  return originalString.replaceAll(placeholder, replacement);
};

// Utility function to add script in the head of the document
export const loadScriptInHead = (src) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = src;
    script.async = true;
    script.onload = () => resolve(script);
    script.onerror = () => reject(false);

    // Prepend the script to the head
    const head = document.head;
    head.insertBefore(script, head.firstChild);
  });
};

export const removeDecimalPart = (value: any) => {
  // Check if the value is a valid string that starts with a '$' and contains a number
  let regex = /^(\$?\d+)(\.\d+)?$/;
  let match = value?.match(regex) || '';

  if (match) {
    // The first capture group is the part before the decimal, return it
    return match[1];
  } else {
    return value; // Return the original value if it doesn't match the expected format
  }
};
export const calculateMonthsUntilUpcomingDate = (upcomingDate: any) => {
  if (upcomingDate) {
    const currentDate = new Date();
    const targetDate = new Date(upcomingDate);

    // If the upcoming date is in the past, return 0 months
    if (targetDate < currentDate) {
      return '';
    }

    // Calculate difference in years and months
    let yearsDiff = targetDate.getFullYear() - currentDate.getFullYear();
    let monthsDiff = targetDate.getMonth() - currentDate.getMonth();

    // If the month difference is negative, adjust by subtracting 1 year
    if (monthsDiff < 0) {
      yearsDiff--;
      monthsDiff += 12;
    }

    // Convert the year difference to months and return the total difference
    return yearsDiff * 12 + monthsDiff;
  } else {
    return '';
  }
};
export const generateCorrelationId = () => {
  return `${Date.now()}-${Math.random().toString(36).substring(2, 15)}`;
};
