/* eslint-disable */
import React, { FC, ReactElement, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { RootState } from '../../../redux/rootReducer';
import withLocalization from '../../hoc/withLocalization';
import './PaymentDetailsPage.scss';
import {
  setPaymentFormToken,
  resetPaymentData,
  bookConfirmationPayment,
  setpaymentLoader,
  createLeadWithBooking,
  resetPaymentContextId,
  resetPaymentModalError,
  payerAuthConfirmation,
  resetAllPaymentPageDetails,
  clearPayerAuthDetails,
  setMailingAddressInfo,
} from '../../../features/newOfferPayment/NewOfferPaymentSlice';
import { bookPaymentPayload } from '../../../features/newOfferPayment/bookPaymentRequestMapping';
import { billingRequestPayload } from '../../../util/leadRequestMapping';
import { logAnalyticData } from '../../../analytics';
import PaymentErrorModal from '../PaymentErrorModal/PaymentErrorModal';
import leftArrowLogo from '../../../features/siteTheme/Brands/tmvcs/logo/leftArrowLogo.svg';
import leftArrowHover from '../../../features/siteTheme/Brands/tmvcs/logo/leftArrowHover.svg';
import { payerAuthPayload } from '../../../util/payerAuthMapping';
import CyberSourceErrorModal from '../../common/CommonOfferComponent/CyberSourceErrorModal/CyberSourceErrorModal';
import { IP_ADDR_REQUEST_URL } from '../../../util/strings';
import { pinFlowPaymentPayload } from '../../../util/pinFlowPaymentRequestMapping';
import { pinFlowAuthPayload } from '../../../util/pinFloPayerAuthMap';
import { RoomType } from '../../../features/newOffer/types';
import { States_US } from '../../../util/States';

const PaymentSubmitPage: FC<any> = (props: any): ReactElement => {
  const { history, localize } = props;
  const newOfferDetailsInfo: any = useSelector((state: RootState) => state.newOfferDetails.newOfferData);
  const isPinOptFlow: boolean = useSelector((state: RootState) => state.pinOptFlowDetails.pinOptFLow);
  const offerDetails: any = useSelector((state: RootState) => state.offerDetails.pinOptOfferDetails);
  const newOfferData = isPinOptFlow ? offerDetails : newOfferDetailsInfo;
  const offerData: any = (newOfferData?.offers && newOfferData?.offers[0]) || '';
  const isFLexFormValid = useSelector((state: RootState) => state.newOfferPaymentDetails.isFLexFormValid);
  const microFormSubmitHandler = useSelector((state: RootState) => state.newOfferPaymentDetails.microFormSubmitHandler);
  const billingErr = useSelector((state: RootState) => state.newOfferPaymentDetails.BillingDetailErr);
  const billingInfo = useSelector((state: RootState) => state.newOfferPaymentDetails.billingDetailInfo);
  const isPaymentCheck = useSelector((state: RootState) => state.newOfferPaymentDetails.isPaymentCheckBox);
  const contactInfo = useSelector((state: RootState) => state.newOfferDetails.reservationContactInfo);
  const pinPospectInfo: any = useSelector((state: RootState) => state.offerDetails.prospect) || '';
  const pinLeadDetails: any = useSelector((state: RootState) => state.pinOptFlowDetails.pinOptFlowLeadInfo);
  const isCallMe: boolean = useSelector((state: RootState) => state.pinOptFlowDetails.isContactMeChecked);
  const pinDetails: any = useSelector((state: RootState) => state.pinOptFlowDetails.pinDetails);
  const getLeadDetail = useSelector((state: RootState) => state.newOfferDetails.newOfferLeadDetail);
  const prospectDetails = useSelector((state: RootState) => state.offerDetails.prospect);
  const newurlParams = useSelector((state: RootState) => state.newOfferDetails.urlData);
  const pinurlParams = useSelector((state: RootState) => state.pinOptFlowDetails.urlData);
  const urlParams = isPinOptFlow ? pinurlParams : newurlParams;
  const pinInfoDetails: any = useSelector((state: RootState) => state.pinOptFlowDetails.pinDetails);
  const pin = pinInfoDetails?.pin || '';
  const isModalErr = useSelector((state: RootState) => state.newOfferPaymentDetails.paymentModalError);
  const modalErrMsg = useSelector((state: RootState) => state.newOfferPaymentDetails.paymentModalErrMsg);
  const modalErrStatus = useSelector((state: RootState) => state.newOfferPaymentDetails.paymentModalErrStatus);
  const payAuthDetails = useSelector((state: RootState) => state.newOfferPaymentDetails.PayerAuthDetails);
  const isPaymentErr = useSelector((state: RootState) => state.newOfferPaymentDetails.paymentError);
  const hasContextError = useSelector((state: RootState) => state.newOfferPaymentDetails.hasPaymentContextError);
  const formTocken = useSelector((state: RootState) => state.newOfferPaymentDetails.flexFormTokenJwt);
  const authEnrollDetails = useSelector((state: RootState) => state.newOfferPaymentDetails.paymentAuthEnrollDetails);
  const payerAuthRefId = useSelector((state: RootState) => state.newOfferPaymentDetails.referenceId);
  const confirmationDetails = useSelector((state: RootState) => state.newOfferPaymentDetails.confirmationDetails);
  const mailingErr = useSelector((state: RootState) => state.newOfferPaymentDetails.mailingAddressErr);
  const mailingData: any = useSelector((state: RootState) => state.newOfferPaymentDetails.mailingAddressInfo);
  const webUrl = urlParams?.url || '';
  const getOfferType: any = sessionStorage.getItem('offer-type');
  const isPaid = getOfferType === 'paid' ? true : false;
  const isBillingDataValid = (obj: any) => {
    let isInValid = Object.values(obj).every((value) => value === false);
    return isInValid;
  };
  const isMailingAddrValid = isBillingDataValid(mailingErr);
  const isMailInfoValid = isPinOptFlow ? (isMailingAddrValid ? true : false) : true;
  const isPayBtnDisabled = isBillingDataValid(billingErr);
  const btnEnable = isPayBtnDisabled && isFLexFormValid && isPaymentCheck && isMailInfoValid;
  const loc = offerData?.loc || '';

  const [isTokenErr, setIsTokenErr] = useState(false);
  const [showErrModal, setShowErrModal] = useState(false);
  const [ipAddress, setSetIpAddress] = useState('');
  const dispatch = useDispatch();
  const handleClickOnBack = () => {
    dispatch(resetPaymentData());
    if (isPinOptFlow) {
      const getUrlSearchParam = sessionStorage.getItem('pinOptUrlSearchParam') || '';
      history.push('/offer-details' + getUrlSearchParam);
    } else {
      const getUrlSearchParam = sessionStorage.getItem('urlSearchParam');
      history.push('/contact-information' + getUrlSearchParam);
    }
  };
  useEffect(() => {
    fetch(IP_ADDR_REQUEST_URL)
      .then((response) => response.json())
      .then((data) => {
        const ipAdd = data?.ip || 'Unknown IP Address';
        setSetIpAddress(ipAdd);
      });
  }, []);
  useEffect(() => {
    if (hasContextError) {
      setIsTokenErr(true);
    }
  }, [hasContextError]);
  const submitPayment = (cdlId, refId, trID) => {
    const getToken = sessionStorage.getItem('pbeJwtToken');
    const requestBookingData = isPinOptFlow
      ? pinFlowPaymentPayload(
          offerData,
          billingInfo,
          pinPospectInfo,
          getToken,
          refId,
          trID,
          ipAddress,
          pinLeadDetails,
          isCallMe,
          pinDetails,
          mailingData,
        )
      : bookPaymentPayload(offerData, billingInfo, contactInfo, getToken, getLeadDetail, cdlId, refId, trID, ipAddress);

    if (getLeadDetail || isPinOptFlow) {
      dispatch(bookConfirmationPayment(requestBookingData));
    } else {
      const getOfferType: any = sessionStorage.getItem('offer-type');
      const isPaid = getOfferType === 'paid' ? true : false;
      const paramState = isPaid ? billingInfo?.state : contactInfo?.state;
      const createLeadPayload = billingRequestPayload(
        contactInfo,
        newOfferData,
        true,
        paramState,
        billingInfo?.zipCode,
        webUrl,
      );
      const payloadObj = { leadRequestObj: createLeadPayload, bookingRequestObj: requestBookingData };
      dispatch(createLeadWithBooking(payloadObj));
    }
  };

  const payerAuthEnrollment = (payAttempt) => {
    const cardinal = window.Cardinal;
    console.log('we are in Enrollment');
    cardinal?.continue(
      'cca',
      {
        AcsUrl: payAttempt.acsUrl,
        Payload: payAttempt.paReq,
      },
      {
        OrderDetails: {
          TransactionId: payAttempt.transactionId,
        },
      },
    );

    cardinal?.on('payments.validated', async (data, cardinaljwt) => {
      console.log('After cardinal CONTINUE we are in payment validate data - ', data?.ActionCode);
      const validateData = data?.ActionCode?.toUpperCase() || 'default';
      if (validateData === 'FAILURE' || validateData === 'ERROR') {
        setShowErrModal(true);
      } else {
        const prTransId = data?.Payment?.ProcessorTransactionId || payAttempt.transactionId;
        submitPayment('', '', prTransId);
      }
    });
  };
  const setupCardinal = (cardinalJwt: any, referenceId: any, token: any, methodName?: any) => {
    const cardinal = window.Cardinal;
    console.log('we are in cardinal setup');
    console.log('is window cardinal define - ', cardinal);

    cardinal?.configure({
      logging: {
        level: 'on',
      },
    });
    cardinal?.setup('init', {
      jwt: cardinalJwt,
    });
    cardinal?.on('payments.setupComplete', function (setupCompleteData) {
      console.log('submit OTP');
      submitPayment(cardinalJwt, payerAuthRefId, '');
    });
  };

  useEffect(() => {
    if (payAuthDetails && payAuthDetails?.payerAuthEnabled && payAuthDetails?.referenceId !== '') {
      //dispatch(setpaymentLoader(false));
      const getToken = sessionStorage.getItem('pbeJwtToken');
      setupCardinal(payAuthDetails?.cardinalJwt, payAuthDetails?.referenceId, getToken);
    }
  }, [payAuthDetails]);
  useEffect(() => {
    if (authEnrollDetails?.status?.toLowerCase() === 'pending_authentication') {
      //dispatch(setpaymentLoader(false));
      payerAuthEnrollment(authEnrollDetails);
    }
  }, [authEnrollDetails]);

  useEffect(() => {
    const confirmationStatus = confirmationDetails?.status?.toLowerCase() || '';
    if (confirmationStatus === 'pending_authentication') {
      payerAuthEnrollment(confirmationDetails);
    }
  }, [confirmationDetails]);

  useEffect(() => {
    if (isModalErr && modalErrStatus) {
      const errSt = modalErrStatus.toUpperCase();
      if (errSt === 'IIB_ERROR' || errSt === 'PAYMENT_DONE_BOOKING_FAILED') {
        if (isPinOptFlow) {
          const getUrlSearchParam = sessionStorage.getItem('pinOptUrlSearchParam') || '';
          history.push('/thank-you' + getUrlSearchParam);
        } else {
          const getUrlSearchParam = sessionStorage.getItem('urlSearchParam');
          history.push('/thank-you' + getUrlSearchParam);
        }
      } else {
        setShowErrModal(true);
      }
    }
  }, [isModalErr, modalErrStatus]);
  if (isPaymentErr) {
    history.push({
      pathname: '/error',
    });
  }
  const analyliticFunc = () => {
    const roomType: RoomType = (offerData?.roomTypes && offerData?.roomTypes[0]) || {};
    const stateCode = roomType?.property?.stateCode;
    const flowIdentifier = isPinOptFlow ? 'pin' : isPaid ? 'paid' : 'organic';
    const destination = urlParams?.destination;
    const brand = urlParams?.brand && urlParams?.brand.length > 0 ? urlParams?.brand : roomType?.property?.brandName;
    const offerCode = offerData?.offerCode || 'Vacation Offer';
    const offerType = () => {
      const showCalendar = offerData?.showCalendar;
      switch (showCalendar) {
        case 'H':
          return 'Open-Dated';
        case 'S':
          return 'Dated';
        default:
          return 'Open-Dated';
      }
    };
    window.dataLayer.push({ ecommerce: null });
    const pushToDataLayer = () => {
      window.dataLayer.push({
        event: 'add_payment_info',
        ecommerce: {
          currency: 'USD',
          value: roomType?.depositAmount?.replace(/[$,]/g, '') || '',
          items: [
            {
              item_id: roomType?.property?.marshaCode,
              item_name: roomType?.property?.name,
              item_brand: brand,
              item_category: destination,
              item_category2: loc,
              item_category3: 'USA',
              item_category4: stateCode,
              item_category5: roomType?.property?.city,
              item_category6: isPaid ? '' : contactInfo?.state ?? prospectDetails?.stateProvince,
              item_category7: offerType(),
              item_category8: offerCode,
              item_category9: pin,
              price: roomType?.depositAmount?.replace(/[$,]/g, '') || '',
              retail_price: offerData?.retailValue?.replace(/[$,]/g, '') || '',
              package_price: roomType?.startingCost?.replace(/[$,]/g, '') || '',
              flow_identifier: flowIdentifier,
              quantity: 1,
            },
          ],
        },
      });
    };
    pushToDataLayer();
    logAnalyticData(
      {
        event: 'PbeSubmitPayment',
        siteSection: 'MVC Optimized PBE',
        pageName: 'MVC | PBE Step2 Add Payment Information',
        pageHierarchy: '',
        server: 'MVC',
        website: 'MVC react',
        language: 'en',
      },
      {
        formLOC: loc, //
        destination: destination,
        resortCity: roomType?.property?.city,
        resortState: stateCode,
        resortCountry: 'USA',
        resortName: roomType?.property?.name,
        resortBrand: brand,
        offerCode: offerCode,
        offerType: offerType(),
        flowIdentifier: flowIdentifier,
        retailPrice: offerData?.retailValue?.replace(/[$,]/g, '') || '',
        packagePrice: roomType?.startingCost?.replace(/[$,]/g, '') || '',
        depositPrice: roomType?.depositAmount?.replace(/[$,]/g, '') || '',
        currency: 'USD',
        quanity: '1',
        resortMarsha: roomType?.property?.marshaCode,
        userSelectedState: isPaid ? billingInfo?.state : contactInfo?.state ?? prospectDetails?.stateProvince,
        bookingStepName: 'MVC I PBE Payment',
      },
    );
  };
  const handleClick = (event) => {
    event.preventDefault();
    if (btnEnable) {
      analyliticFunc();
      if (microFormSubmitHandler) {
        microFormSubmitHandler((err, token) => {
          // dispatch(setpaymentLoader(false));
          if (!err) {
            sessionStorage.setItem('pbeJwtToken', token);
            dispatch(setPaymentFormToken(token));
            const requestBookingData = isPinOptFlow
              ? pinFlowPaymentPayload(
                  offerData,
                  billingInfo,
                  pinPospectInfo,
                  token,
                  '',
                  '',
                  ipAddress,
                  pinLeadDetails,
                  isCallMe,
                  pinDetails,
                  mailingData,
                )
              : bookPaymentPayload(offerData, billingInfo, contactInfo, token, getLeadDetail, '', '', '', ipAddress);
            const authData = isPinOptFlow
              ? pinFlowAuthPayload(offerData, billingInfo, pinPospectInfo, token, ipAddress, pinLeadDetails)
              : payerAuthPayload(offerData, billingInfo, contactInfo, token, ipAddress);
            const isLeadId = getLeadDetail ? true : false;
            const getOfferType: any = sessionStorage.getItem('offer-type');
            const isPaid = getOfferType === 'paid' ? true : false;
            const paramState = isPaid ? billingInfo?.state : contactInfo?.state;
            const createLeadPayload = isPinOptFlow
              ? {}
              : billingRequestPayload(contactInfo, newOfferData, true, paramState, billingInfo?.zipCode, webUrl);

            const payloadObj = {
              leadRequestObj: createLeadPayload,
              bookingRequestObj: requestBookingData,
              authDataObj: authData,
              isLeadId: isLeadId,
              ispinOptFlow: isPinOptFlow,
            };
            dispatch(payerAuthConfirmation(payloadObj));
          } else {
            dispatch(setpaymentLoader(false));
            setIsTokenErr(true);
            console.log(err);
          }
        });
      }
    }
  };
  const closePaymentErrModal = () => {
    dispatch(resetPaymentData());
    dispatch(resetPaymentContextId());
    dispatch(resetPaymentModalError());
    dispatch(resetAllPaymentPageDetails());
    dispatch(clearPayerAuthDetails());
    setShowErrModal(false);
    setIsTokenErr(false);
    const stateData = States_US.find((item) => item.code === prospectDetails?.stateProvince);
    const payloadData = {
      address: prospectDetails?.addressLine1 || '',
      city: prospectDetails?.city || '',
      zipCode: prospectDetails?.postalCode || '',
      state: stateData?.name || '',
    };
    dispatch(setMailingAddressInfo(payloadData));
    sessionStorage.removeItem('pbeJwtToken');
  };
  return (
    <>
      {showErrModal && <PaymentErrorModal closeModal={closePaymentErrModal} msg={modalErrMsg} />}
      {isTokenErr && <CyberSourceErrorModal closeModal={closePaymentErrModal} />}
      <div className="payment-btn-container">
        <button onClick={handleClickOnBack} className={`payment-back-btn`}>
          <img src={leftArrowLogo} alt="Back" className="back-icon-payment" />
          <img src={leftArrowHover} alt="Back" className="back-icon-payment-hover" />
          {localize(`preview-sales-engine.static-content.copy.pbeo.back-button-label`)}
        </button>
        <button onClick={handleClick} className={!btnEnable ? `payment-disable-submit-btn` : `payment-submit-btn`}>
          {localize(`preview-sales-engine.static-content.copy.pbeo.submit-payment-button-label`)}
        </button>
      </div>
    </>
  );
};

export default withRouter(withLocalization(PaymentSubmitPage));
