/* eslint-disable */
import { calculateMonthsUntilUpcomingDate } from "./CommonLogic";
const subtractDollarValues = (value1: any, value2: any) => {
  if (value1 === '' || value2 === '' || value1 === undefined || value2 === undefined) {
    return '';
  }
  const num1 = parseFloat(value1.replace('$', ''));
  const num2 = parseFloat(value2.replace('$', ''));
  const result = num1 - num2;
  const formattedResult = '$' + result.toFixed(2);
  return formattedResult;
};

export const mappedSummaryData = (phone: any, offerData: any) => {
  const offerDetails = (offerData?.offers && offerData?.offers[0]) || {};
  const roomType = (offerDetails?.roomTypes && offerDetails?.roomTypes[0]) || {};
  const property = roomType?.property || {};
  const propertyLocation = `${property?.address1}, ${property?.city}, ${property?.stateProvince}`;
  const orderDays = roomType?.numberOfDays || '';
  const orderNight = roomType?.numberOfNights || '';
  const orderStay = `${orderDays} days / ${orderNight} nights`;
  const gift = (offerDetails?.premiums && offerDetails?.premiums[0]) || '';
  const startingCast = roomType?.startingCost || '';
  const depositAmt = roomType?.depositAmount || '';
  const balance = subtractDollarValues(startingCast, depositAmt);
  const bookToDate = offerDetails?.bookToDate || '';
  const bookFromDate = offerDetails?.bookFromDate || '';
  //const bookDate = calculateMonthsBetweenDates(bookFromDate, bookToDate);
  const difDates = calculateMonthsUntilUpcomingDate(offerDetails?.finalDate);
  const bookDate = difDates || '';
  const summaryObj = {
    locator: phone,
    location: propertyLocation,
    stay: orderStay,
    accommodation: roomType?.roomTypeDescription,
    dates: bookDate,
    maxGuest: roomType?.sleepCapacity,
    giftOpt: gift,
    totalPrice: startingCast,
    depositPaid: depositAmt,
    balance: balance,
    days: orderDays,
    nights: orderNight,
  };
  return summaryObj;
};
