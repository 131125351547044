/* eslint-disable */
import React, { FC, useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import withLocalization from '../../hoc/withLocalization';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/rootReducer';
import travelLeft from '../../../features/siteTheme/Brands/tmvcs/travel/travel-left.png';
import travelRight from '../../../features/siteTheme/Brands/tmvcs/travel/travel-right.png';
import { subtractDollarValues } from '../../../util/CommonLogic';
import {
  calculateMonthsBetweenDates,
  replacePlaceholder,
  calculateMonthsUntilUpcomingDate,
} from '../../../util/CommonLogic';
import { logAnalyticData } from '../../../analytics';
import ParseHtml from '../../common/ParseHtml';
import './ThankYouDetailsPage.scss';

interface ReservationPanelProps {
  localize?: any;
  localizeImages?: any;
  history: any;
}
const ThankYouDetailsPage: FC<ReservationPanelProps> = (props: ReservationPanelProps) => {
  const { localize } = props;
  const contactInfo = useSelector((state: RootState) => state.newOfferDetails.reservationContactInfo);
  const bookingConfirmation: any = useSelector((state: RootState) => state.newOfferPaymentDetails.confirmationDetails);
  const newOfferDataByLoc: any = useSelector((state: RootState) => state.newOfferDetails.newOfferData);
  const isPinOptFlow: boolean = useSelector((state: RootState) => state.pinOptFlowDetails.pinOptFLow);
  const pinOfferDetails: any = useSelector((state: RootState) => state.offerDetails.pinOptOfferDetails);
  const newOfferData = isPinOptFlow ? pinOfferDetails : newOfferDataByLoc;
  const newurlParams = useSelector((state: RootState) => state.newOfferDetails.urlData);
  const pinurlParams = useSelector((state: RootState) => state.pinOptFlowDetails.urlData);
  const urlParams = isPinOptFlow ? pinurlParams : newurlParams;
  const pinInfoDetails: any = useSelector((state: RootState) => state.pinOptFlowDetails.pinDetails);
  const pin = pinInfoDetails?.pin || '';
  const billingInfo = useSelector((state: RootState) => state.newOfferPaymentDetails.billingDetailInfo);
  const getLeadDetail = useSelector((state: RootState) => state.newOfferDetails.newOfferLeadDetail);
  const [refrenceNo, setRefrenceNo] = useState('');
  const pinLeadDetails: any = useSelector((state: RootState) => state.pinOptFlowDetails.pinOptFlowLeadInfo);
  const offerDetails = (newOfferData?.offers && newOfferData?.offers[0]) || {};
  const pinPospectInfo: any = useSelector((state: RootState) => state.offerDetails.prospect) || '';

  const pinEmail = pinPospectInfo?.email || pinLeadDetails?.email;
  const pinPhNo = pinPospectInfo?.phone || pinLeadDetails?.phone;
  const finalMail = isPinOptFlow ? pinEmail : contactInfo?.emailAddress;
  const finalPhone = isPinOptFlow ? pinPhNo : contactInfo?.phoneNumber;

  const getOfferType: any = sessionStorage.getItem('offer-type');
  const isPaid = getOfferType === 'paid' ? true : false;
  const loc = (newOfferData?.offers && newOfferData?.offers[0]?.loc) || '';
  const PhoneNo =
    (newOfferData?.offers && newOfferData?.offers[0]?.locPhoneNumber) ||
    localize('preview-sales-engine.static-content.copy.pbeo.default-loc-phone');
  const stPhone = localize('preview-sales-engine.static-content.copy.pbeo.default-activation-phone');

  const bookToDate = offerDetails?.bookToDate || '';
  const bookFromDate = offerDetails?.bookFromDate || '';
  //const totalMonths: any = calculateMonthsBetweenDates(bookFromDate, bookToDate);
  const difDates = calculateMonthsUntilUpcomingDate(offerDetails?.finalDate);
  const totalMonths: any = difDates || '';
  //const confirmationNumber = bookingConfirmation?.ConfirmationNumber || '';
  const thankHedDec = localize('preview-sales-engine.static-content.copy.pbeo.confirmation-page-description');
  const updatedContent = replacePlaceholder(
    thankHedDec,
    '{0}.',
    `<span class="header-mail-wrapper">${finalMail}</span>`,
  );
  const confirmationDataContent = `${localize(
    'preview-sales-engine.static-content.copy.pbeo.confirmation-number-text',
  )} `;
  const confirmationMessage = replacePlaceholder(
    confirmationDataContent,
    ':',
    `: <span class="header-phone-wrapper">${finalPhone}</span>`,
  );

  const step1 = `${localize('preview-sales-engine.static-content.copy.pbeo.what-happens-step-1-text')}`;
  const step2 = `${localize('preview-sales-engine.static-content.copy.pbeo.what-happens-step-2-text')}`;
  const updatedMonth = replacePlaceholder(step2, '{0}', totalMonths);

  const updatedStep2 = updatedMonth?.replaceAll(`{1}`, stPhone);
  const refineStep2 = replacePlaceholder(updatedStep2, 'textHeaderLinkWraper', 'OrderSummaryNumWraper');

  const step3 = `${localize('preview-sales-engine.static-content.copy.pbeo.what-happens-step-3-text')}`;

  const updatedStep3 = replacePlaceholder(step3, `begin!</span></div>\n\n`, `begin!</span>`);
  const refineStep3 = `${updatedStep3} </div>`;

  useEffect(() => {
    const roomType = (offerDetails?.roomTypes && offerDetails?.roomTypes[0]) || {};
    const stateCode = roomType?.property?.stateCode;
    const flowIdentifier = isPinOptFlow ? 'pin' : isPaid ? 'paid' : 'organic';
    const pkgDueAtAmt = subtractDollarValues(roomType?.startingCost, roomType?.depositAmount);
    const destination = urlParams?.destination;
    const brand = urlParams?.brand && urlParams?.brand.length > 0 ? urlParams?.brand : roomType?.property?.brandName;
    const offerCode = offerDetails?.offerCode || 'Vacation Offer';
    const offerType = () => {
      const showCalendar = offerDetails?.showCalendar;
      switch (showCalendar) {
        case 'H':
          return 'Open-Dated';
        case 'S':
          return 'Dated';
        default:
          return 'Open-Dated';
      }
    };
    window.dataLayer.push({ ecommerce: null });
    const pushToDataLayer = () => {
      window.dataLayer.push({
        event: 'purchase',
        ecommerce: {
          transaction_id: getLeadDetail,
          currency: 'USD',
          value: roomType?.depositAmount?.replace(/[$,]/g, '') || '',
          items: [
            {
              item_id: roomType?.property?.marshaCode,
              item_name: roomType?.property?.name,
              item_brand: brand,
              item_category: destination,
              item_category2: loc,
              item_category3: 'USA',
              item_category4: stateCode,
              item_category5: roomType?.property?.city,
              item_category6: isPaid ? billingInfo?.state : contactInfo?.state ?? pinPospectInfo?.stateProvince,
              item_category7: offerType(),
              item_category8: offerCode,
              item_category9: pin,
              price: roomType?.depositAmount?.replace(/[$,]/g, '') || '',
              retail_price: offerDetails?.retailValue?.replace(/[$,]/g, '') || '',
              package_price: roomType?.startingCost?.replace(/[$,]/g, '') || '',
              flow_identifier: flowIdentifier,
              quantity: 1,
            },
          ],
        },
      });
    };
    pushToDataLayer();
    logAnalyticData(
      {
        event: 'PbeConfirmation',
        siteSection: 'MVC Optimized PBE',
        pageName: 'MVC | PBE Confirmation',
        pageHierarchy: '',
        server: 'MVC',
        website: 'MVC react',
        language: 'en',
      },
      {
        formLOC: loc,
        destination: destination,
        resortCity: roomType?.property?.city,
        resortState: roomType?.property?.stateCode,
        resortCountry: 'USA',
        resortName: roomType?.property?.name,
        resortBrand: brand,
        offerCode: offerCode,
        offerType: offerType(),
        flowIdentifier: flowIdentifier,
        retailPrice: offerDetails?.retailValue?.replace(/[$,]/g, '') || '',
        packagePrice: roomType?.startingCost?.replace(/[$,]/g, '') || '',
        depositPrice: roomType?.depositAmount?.replace(/[$,]/g, '') || '',
        amountPaid: roomType?.depositAmount?.replace(/[$,]/g, '') || '',
        currency: 'USD',
        quanity: '1',
        resortMarsha: roomType?.property?.marshaCode,
        userSelectedState: isPaid ? billingInfo?.state : contactInfo?.state ?? pinPospectInfo?.stateProvince,
        bookingStepName: 'MVC | PBE Confirmation',
      },
    );
  }, []);
  useEffect(() => {
    if (refrenceNo === '') {
      const refNo = bookingConfirmation?.ConfirmationNumber || '';
      setRefrenceNo(refNo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="thankYouTopSection">
        <div className="thankYouHeaderSection">
          <span className="thankYou-header-Title">
            {localize('preview-sales-engine.static-content.copy.pbeo.thank-you-success-header')}
          </span>
          <div className="thankyou-header-detail-container">
            <div className="header-mail-section">
              <div>
                <ParseHtml htmlInput={updatedContent}></ParseHtml>
              </div>
            </div>
            <div className="header-phone-section">
              <ParseHtml htmlInput={confirmationMessage}></ParseHtml>
            </div>
          </div>
        </div>
        <div className="thankYou-desc-section">
          <span className="thankYou-desc-header">
            {localize('preview-sales-engine.static-content.copy.pbeo.what-happens-next-section-header')}
          </span>
          <div className="desc-list-container">
            <div className="desc-listItem-sction">
              <ParseHtml htmlInput={step1}></ParseHtml>
            </div>

            <div className="desc-listItem-sction">
              <ParseHtml htmlInput={refineStep2}></ParseHtml>
            </div>
            <div className="desc-listItem-sction">
              <ParseHtml htmlInput={refineStep3}></ParseHtml>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default withRouter(withLocalization(ThankYouDetailsPage));
