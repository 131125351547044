/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import axios, { AxiosInstance } from 'axios';
import { OptinPinDetailsType } from './types';

const axiosInstance: AxiosInstance = axios.create({
    headers: {
        'Content-Type': 'application/json',
    },
});

export const addOptinPinDetails = async (payload: OptinPinDetailsType, correlationId: any): Promise<any> => {
    let returnValue: any = {
        hasDetailsError: false,
    };
    const optinApiUrl: string = `${process.env.REACT_APP_API_DOMAIN}${process.env.REACT_APP_ADD_OPTIN_PIN_DETAILS_REQUEST_KEY}?optFlow=Y`;
    //const optinApiUrl = `https://qm07-gw-dmz-mm-proxy-pse-api-v1.apps.vacationclub.io/proxy/pse/v1/addOptInPinDetails`;
    try {
        const optinResponse = await axiosInstance.post<OptinPinDetailsType, any>(optinApiUrl, payload, {
            headers: {
                'x-correlation-id': correlationId,
            }
        },);
        returnValue.OptinDetail = optinResponse;
    } catch (e: any) {
        returnValue.hasDetailsError = true;
    }
    return returnValue;
};
