import React, { FC, ReactElement, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import withLocalization from '../../components/hoc/withLocalization';
import EditLeadInfoDetails from '../../components/LeadInfoDetails/EditLeadInfoDetails';
import LeadInfoDetails from '../../components/LeadInfoDetails/LeadInfoDetails';
import OfferHeader from '../../components/common/CommonOfferComponent/OfferHeader/OfferHeader';
import { RootState } from '../../redux/rootReducer';
import { logAnalyticData } from '../../analytics';
import { useMediaQuery } from 'react-responsive';
import { Offer, OfferResponse } from '../../features/offerSearch/types';

import './PinOptLeadInfoContainer.scss';

const PinOptLeadInfoContainer: FC<any> = (props: any): ReactElement => {
  const { history, localizeImages, localize } = props;
  const isMobile = useMediaQuery({ query: `(max-width: 500px)` });
  const { path } = localizeImages('preview-sales-engine.static-content.images.verify-page-hero', 0, '1:1');
  const mobPath = localizeImages('preview-sales-engine.static-content.images.verify-page-hero', 0, '7:3');
  const leadInfoDetails: any = useSelector((state: RootState) => state.offerDetails.prospect) || '';
  const prspectPhone: any = useSelector((state: RootState) => state.offerDetails.nonFormatedCallcenterNo) || '';
  const phNo = localize('preview-sales-engine.static-content.copy.prospects.brandphonenumber');
  const offerDetails: OfferResponse = useSelector((state: RootState) => state.offerDetails.pinOptOfferDetails);
  const offer: Offer = (offerDetails?.offers && offerDetails.offers[0]) || '';
  const offerCode = offer?.offerCode || 'Vacation Offer';
  const callCenterNo = prspectPhone || '';
  const leadInfoPhNo = callCenterNo || phNo;
  const imgPath = isMobile ? mobPath?.path : path;
  const offerType = () => {
    const showCalendar = offer?.showCalendar;
    switch (showCalendar) {
      case 'H':
        return 'Open-Dated';
      case 'S':
        return 'Dated';
      default:
        return 'Open-Dated';
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!leadInfoDetails) history.push('/pin-error');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    logAnalyticData(
      {
        event: 'pbeVerifyPage',
        siteSection: 'MVC Optimized PBE',
        pageName: 'MVC PBE Verify Page',
        pageHierarchy: '',
        server: 'MVC',
        website: 'MVC react',
        language: 'en',
      },
      {
        errorMessage: '', //should we put the same error as displayed in pin-error page?,
        offerCode: offerCode,
        offerType: offerType(),
        flowldentifier: 'PIN',
        bookingStepName: 'MVC I PBE Verify Page',
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkEmptyFields = () => {
    const keysToCheck = ['firstName', 'lastName', 'email', 'phone'];
    for (let key of keysToCheck) {
      if (!leadInfoDetails[key] || leadInfoDetails[key] === '') {
        return false;
      }
    }
    return true;
  };
  const styleBg = {
    backgroundImage: `url("${imgPath}")`,
  };
  return (
    <>
      <OfferHeader phone={leadInfoPhNo} isThankyou={false} />
      <div className="tmvc-leadInfo-Main-container">
        <div className="leadInfo-container">
          <div className="leadInfo-inner-container">
            {checkEmptyFields() ? (
              <LeadInfoDetails leadDetails={leadInfoDetails} />
            ) : (
              <EditLeadInfoDetails leadDetails={leadInfoDetails} />
            )}
          </div>
        </div>
        <div className="lead-main-img-wraper">
          <div className="pinFlowImg-bg-Lead" style={styleBg}></div>
        </div>

        {/*<div className="pinFLow-leadInfo-img">
          <img src={imgPath} alt="main" className="pinFlowImg" />
        </div>*/}
      </div>
    </>
  );
};
export default withRouter(withLocalization(PinOptLeadInfoContainer));
