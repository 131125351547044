/* eslint-disable */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, AppThunk } from '../../redux/store';
import { newOfferPaymentDetailType, billingInfoType, paymentRequestType, PaymentContextResponse } from './types';
import {
  submitBookingPayment,
  getPaymentContext,
  createLeadBeforeBooking,
  setUpPayerAuth,
  paymentAuth,
  paymentConfirmation,
} from './NewOfferPaymentService';
const initialState: newOfferPaymentDetailType = {
  billingDetailInfo: { address: '', city: '', state: '', zipCode: '' },
  mailingAddressInfo: { address: '', city: '', state: '', zipCode: '' },
  mailingAddressErr: { addressError: true, cityError: true, stateError: true, zipCodeError: true },
  pageLoading: false,
  hasError: false,
  BillingDetailErr: { addressError: true, cityError: true, zipCodeError: true, stateError: true },
  isPaymentCheckBox: false,
  isFLexFormValid: false,
  hasPaymentContextError: false,
  confirmationDetails: undefined,
  paymentModalError: false,
  paymentModalErrMsg: '',
  paymentModalErrStatus: '',
  paymentError: false,
  paymentErrorStatus: '',
  PayerAuthDetails: undefined,
  cardinalJwt: '',
  referenceId: '',
  paymentAuthEnrollDetails: undefined,
  paymentconfirmationData: undefined,
  isSameBillingAddress: false
};
const newOfferPaymentSlice = createSlice({
  name: 'PaymentPage',
  initialState,
  reducers: {
    setBillingDetailsInfo(state: newOfferPaymentDetailType, action: PayloadAction<any>) {
      state.billingDetailInfo = {
        address: action.payload.address,
        city: action.payload.city,
        state: action.payload.state,
        zipCode: action.payload.zipCode,
      };
    },
    setMailingAddressInfo(state: newOfferPaymentDetailType, action: PayloadAction<any>) {
      state.mailingAddressInfo = {
        address: action.payload.address,
        city: action.payload.city,
        state: action.payload.state,
        zipCode: action.payload.zipCode,
      };
    },
    setMailingAddressErrInfo(state: newOfferPaymentDetailType, action: PayloadAction<any>) {
      state.mailingAddressErr = {
        addressError: action.payload.addressError,
        cityError: action.payload.cityError,
        stateError: action.payload.stateError,
        zipCodeError: action.payload.zipCodeError,
      };
    },
    setBillingErrInfo(state: newOfferPaymentDetailType, action: PayloadAction<any>) {
      state.BillingDetailErr = {
        addressError: action.payload.addressError,
        cityError: action.payload.cityError,
        stateError: action.payload.stateError,
        zipCodeError: action.payload.zipCodeError,
      };
    },
    setMicroFormSubmitHandler(state, action: PayloadAction<any>) {
      state.microFormSubmitHandler = action.payload;
    },
    setNewPaymentcheckBox(state, action: PayloadAction<boolean>) {
      state.isPaymentCheckBox = action.payload;
    },
    setIdenticalBillingAddress(state, action: PayloadAction<boolean>) {
      state.isSameBillingAddress = action.payload;
    },
    setPaymentFormToken(state, action: PayloadAction<string>) {
      state.flexFormTokenJwt = action.payload;
    },
    setFlexFormValidState(state, action: PayloadAction<boolean>) {
      state.isFLexFormValid = action.payload;
    },
    resetPaymentData(state) {
      state.isFLexFormValid = false;
      state.flexFormTokenJwt = undefined;
      state.isPaymentCheckBox = false;
      state.BillingDetailErr = {
        addressError: true,
        cityError: true,
        stateError: true,
        zipCodeError: true,
      };
      state.billingDetailInfo = { address: '', city: '', state: '', zipCode: '' };
      state.pageLoading = false;
      //state.mailingAddressInfo = { address: '', city: '', state: '', zipCode: '' };
      //state.mailingAddressErr = { addressError: true, cityError: true, stateError: true, zipCodeError: true };
      state.isSameBillingAddress = false;
    },
    resetconfirmationDetails(state) {
      state.confirmationDetails = undefined;
      state.hasError = false;
    },
    resetPaymentModalError(state) {
      state.paymentModalErrMsg = '';
      state.paymentModalErrStatus = '';
      state.pageLoading = false;
      state.paymentModalError = false;
    },
    resetAllPaymentPageDetails(state) {
      state.pageLoading = false;
      state.confirmationDetails = undefined;
      state.paymentModalError = false;
      state.paymentModalErrMsg = '';
      state.paymentModalErrStatus = '';
      state.paymentError = false;
      state.paymentErrorStatus = '';
      state.PayerAuthDetails = undefined;
      state.cardinalJwt = '';
      state.referenceId = '';
      state.paymentAuthEnrollDetails = undefined;
      state.paymentconfirmationData = undefined;
    },

    setPaymentContextId(state, action: PayloadAction<PaymentContextResponse>) {
      state.hasPaymentContextError = action.payload.hasError;
      state.error = action.payload.error;
      state.paymentContextId =
        action.payload.contextTokens && action.payload.contextTokens.length > 0
          ? action.payload.contextTokens[0].key
          : '';
    },
    setNewOfferPaymentLoader(state, action: PayloadAction<boolean>) {
      state.pageLoading = action.payload;
    },
    resetPaymentContextId(state, action: PayloadAction<void>) {
      state.paymentContextId = undefined;
      state.hasPaymentContextError = false;
    },
    setpaymentLoader(state, action: PayloadAction<boolean>) {
      state.pageLoading = action.payload;
    },
    clearPayerAuthDetails(state, action: PayloadAction<void>) {
      state.PayerAuthDetails = undefined;
      state.referenceId = '';
      state.cardinalJwt = '';
    },
    clearPaymentPage(state) {
      state.isPaymentCheckBox = false;
      state.isFLexFormValid = false;
      state.hasPaymentContextError = false;
      state.confirmationDetails = undefined;
      state.paymentModalError = false;
      state.paymentModalErrMsg = '';
      state.paymentModalErrStatus = '';
      state.paymentError = false;
      state.pageLoading = false;
      state.referenceId = '';
      state.cardinalJwt = '';
      state.paymentAuthEnrollDetails = undefined;
      state.PayerAuthDetails = undefined;
    },
    receiveConfirmationDetails(state, action: PayloadAction<any>) {
      if (action.payload.modalError) {
        state.paymentModalError = action.payload.modalError;
        state.paymentModalErrMsg = action.payload.modalErrMsg;
        state.paymentModalErrStatus = action.payload.ModalErrStatus;
      } else if (action.payload.PaymentError) {
        state.paymentError = action.payload.PaymentError;
        state.paymentErrorStatus = action.payload.paymentErrStatus;
      } else {
        state.confirmationDetails = action.payload.bookingResponse;
        state.error = action.payload.error;
        state.hasError = action.payload.PaymentError;
        state.paymentModalError = false;
        state.paymentModalErrMsg = '';
        state.paymentModalErrStatus = '';
        state.referenceId = '';
        state.cardinalJwt = '';
      }
      state.pageLoading = false;
      state.PayerAuthDetails = undefined;
      state.paymentAuthEnrollDetails = undefined;
    },
    setPaymentConfirmationData(state, action: PayloadAction<any>) {
      state.pageLoading = false;
      state.paymentconfirmationData = action.payload.confirmDetails;
      state.PayerAuthDetails = undefined;
      state.paymentAuthEnrollDetails = undefined;
    },
    receivePayerAuthDetails(state, action: PayloadAction<any>) {
      //state.pageLoading = false;
      state.PayerAuthDetails = action.payload.payAuthResponse;
      state.referenceId = action.payload.payAuthResponse?.referenceId;
      state.cardinalJwt = action.payload.payAuthResponse.cardinalJwt;
    },
    setPaymentAuthDetails(state, action: PayloadAction<any>) {
      //state.pageLoading = false;
      state.paymentAuthEnrollDetails = action.payload;
      //state.referenceId = action.payload.payAuthResponse?.referenceId;
      //state.cardinalJwt = action.payload.payAuthResponse.cardinalJwt;
    },
  },
});
export const {
  setBillingDetailsInfo,
  setBillingErrInfo,
  setMicroFormSubmitHandler,
  setNewPaymentcheckBox,
  setPaymentFormToken,
  setFlexFormValidState,
  resetPaymentData,
  setPaymentContextId,
  resetPaymentContextId,
  setpaymentLoader,
  receiveConfirmationDetails,
  resetconfirmationDetails,
  resetPaymentModalError,
  clearPaymentPage,
  receivePayerAuthDetails,
  clearPayerAuthDetails,
  setPaymentAuthDetails,
  setPaymentConfirmationData,
  resetAllPaymentPageDetails,
  setMailingAddressInfo,
  setMailingAddressErrInfo,
  setIdenticalBillingAddress
} = newOfferPaymentSlice.actions;
export const createLeadWithBooking = (param: any): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(newOfferPaymentSlice.actions.setpaymentLoader(true));
  const leadDetail: any = await createLeadBeforeBooking(param.leadRequestObj);

  const lead_id = (leadDetail?.leadDetail?.items && leadDetail?.leadDetail?.items[0]?.id) || '';
  let PaymentRequestObj: any = { ...param.bookingRequestObj };
  if (lead_id) {
    PaymentRequestObj.updateLead.leadId = lead_id;
  } else {
    PaymentRequestObj.updateLead = {
      address: '',
      leadId: '',
      postalCode: '',
      state: '',
      status: '',
      latestLeadOriginCode: '',
    };
  }
  dispatch(bookConfirmationPayment(PaymentRequestObj));
};
export const bookConfirmationPayment = (param: paymentRequestType): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(newOfferPaymentSlice.actions.setpaymentLoader(true));
  const bookingResponse: any = await submitBookingPayment(param);
  if (bookingResponse.PaymentError) {
    const tempError: any = {
      PaymentError: true,
      paymentErrorStatus: bookingResponse?.paymentErrCode,
      bookingResponse: undefined,
    };
    dispatch(newOfferPaymentSlice.actions.receiveConfirmationDetails(tempError));
  } else if (bookingResponse.paymentModalError) {
    const tempError: any = {
      modalError: true,
      modalErrMsg: bookingResponse?.paymentModalErrMsg,
      bookingResponse: undefined,
      ModalErrStatus: bookingResponse?.paymentErrStatus,
    };
    dispatch(newOfferPaymentSlice.actions.receiveConfirmationDetails(tempError));
  } else {
    const confirmationResponse = {
      bookingResponse: bookingResponse.confirmationDetail,
      PaymentError: false,
    };
    dispatch(newOfferPaymentSlice.actions.receiveConfirmationDetails(confirmationResponse));
  }
};
export const retrievePaymentContext = (merchantGroupId: string, origin: string): AppThunk => async (
  dispatch: AppDispatch,
) => {
  dispatch(newOfferPaymentSlice.actions.setpaymentLoader(true));
  const response: PaymentContextResponse = await getPaymentContext(merchantGroupId, origin);
  dispatch(newOfferPaymentSlice.actions.setPaymentContextId(response));
  dispatch(newOfferPaymentSlice.actions.setpaymentLoader(false));
};
export const payerAuthConfirmation = (param: any): AppThunk => async (dispatch: AppDispatch) => {
  const authObj = param?.authDataObj;
  const leadObj = param?.leadRequestObj;
  const paymentObj = param?.bookingRequestObj;
  const islead = param?.isLeadId;
  const isPinOptFlow = param?.ispinOptFlow || false
  dispatch(newOfferPaymentSlice.actions.setpaymentLoader(true));
  const authResponse: any = await setUpPayerAuth(authObj);
  if (
    authResponse?.payAuthResponse &&
    authResponse?.payAuthResponse?.payerAuthEnabled &&
    authResponse?.payAuthResponse?.referenceId !== ''
  ) {
    dispatch(newOfferPaymentSlice.actions.receivePayerAuthDetails(authResponse));
  } else if (authResponse?.payAuthResponse && authResponse?.payAuthResponse?.payerAuthEnabled === false) {
    const updatedObj = { ...paymentObj };
    updatedObj.payment.clientReferenceInformation.transactionId = '';
    updatedObj.payment.clientReferenceInformation.referenceId = '';
    if (islead || isPinOptFlow) {
      dispatch(bookConfirmationPayment(updatedObj));
    } else {
      const requestParam = { leadRequestObj: leadObj, bookingRequestObj: updatedObj };
      dispatch(createLeadWithBooking(requestParam));
    }
  } else {
    const tempError: any = {
      modalError: true,
      modalErrMsg: '',
      bookingResponse: undefined,
      ModalErrStatus: 'unknown',
    };
    dispatch(newOfferPaymentSlice.actions.receiveConfirmationDetails(tempError));
  }
};
export const paymentAuthConfirmation = (param: any): AppThunk => async (dispatch: AppDispatch) => {
  const PaymentAuthObj = param?.PaymentAuthDataObj;
  const leadObj = param?.leadRequestObj;
  const paymentObj = param?.bookingRequestObj;
  const islead = param?.isLeadId;
  const isCheck = param.isComplete;
  dispatch(newOfferPaymentSlice.actions.setpaymentLoader(true));
  const response: any = await paymentAuth(PaymentAuthObj);
  if (
    response?.paymentAuthResponse?.status?.toLowerCase() === 'success' ||
    response?.paymentAuthResponse?.status?.toLowerCase() === 'pending'
  ) {
    const transctionID = response?.payAuthResponse?.transactionId || '';
    const updatedObj = { ...paymentObj };
    updatedObj.payment.clientReferenceInformation.transactionId = transctionID;
    if (isCheck) {
      const confirmObj = {
        isLead: islead,
        leadObj: leadObj,
        requestObj: param?.confirmationObj,
      };
      //dispatch(submitPaymentConfirmation(confirmObj));
    } else {
      if (islead) {
        dispatch(bookConfirmationPayment(updatedObj));
      } else {
        const requestParam = { leadRequestObj: leadObj, bookingRequestObj: paymentObj };
        dispatch(createLeadWithBooking(requestParam));
      }
    }
  } else if (
    response.paymentAuthResponse &&
    response.paymentAuthResponse?.status?.toLowerCase() === 'pending_authentication'
  ) {
    dispatch(newOfferPaymentSlice.actions.setPaymentAuthDetails(response.paymentAuthResponse));
  } else {
    const tempError: any = {
      modalError: true,
      modalErrMsg: '',
      bookingResponse: undefined,
      ModalErrStatus: response?.paymentAuthResponse?.status || 'unknown',
    };
    dispatch(newOfferPaymentSlice.actions.receiveConfirmationDetails(tempError));
  }
};

export default newOfferPaymentSlice.reducer;
