/* eslint-disable prettier/prettier */

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, AppThunk } from '../../redux/store';
import { addOptinPinDetails } from './PinOptFlowOfferService';
import { pinOptFlowOfferType, OptinPinDetailsType } from './types';

const initialState: pinOptFlowOfferType = {
  pageLoading: false,
  hasError: false,
  pinOptFLow: false,
  pinDetails: undefined,
  pinOptFlowLeadInfo: { email: '', phone: '' },
  isContactMeChecked: false,
  urlData: undefined,
};

const pinOptFlowSlice = createSlice({
  name: 'PinOptFlowDetails',
  initialState,
  reducers: {
    setOfferFlowDetails(state, action: PayloadAction<boolean>) {
      state.pinOptFLow = action.payload;
    },

    setPinOptFlowLoader(state, action: PayloadAction<boolean>) {
      state.pageLoading = action.payload;
    },
    setPinLnDetails(state, action: PayloadAction<boolean>) {
      state.pinDetails = action.payload;
    },
    setPinOptFlowLeadInfo(state, action: PayloadAction<any>) {
      state.pinOptFlowLeadInfo = {
        email: action?.payload?.email,
        phone: action?.payload?.phone,
      };
    },
    setLeadInfoCheckBox(state, action: PayloadAction<boolean>) {
      state.isContactMeChecked = action.payload;
    },
    setUrlDetails(state, action: PayloadAction<any>) {
      const destination = action.payload.destination || '';
      const brand = action.payload.brand || '';
      state.urlData = {
        url: action.payload.url,
        destination: destination,
        brand: brand,
      };
    },
    setOptinPinDetails() { },
    resetPinOptFlowDetails(state) {
      state.isContactMeChecked = false;
      state.pinOptFLow = false;
      state.hasError = false;
      state.pinDetails = undefined;
      state.pinOptFlowLeadInfo = { email: '', phone: '' };
      state.urlData = undefined;
    },
  },
});
export const {
  setOfferFlowDetails,
  setPinLnDetails,
  setPinOptFlowLeadInfo,
  setLeadInfoCheckBox,
  setUrlDetails,
  resetPinOptFlowDetails,
} = pinOptFlowSlice.actions;
export const addOptinPinDetailsRequest = (param: OptinPinDetailsType, id: any): AppThunk => async (dispatch: AppDispatch) => {
  const optinDetail: any = await addOptinPinDetails(param, id);
  //dispatch(pinOptFlowSlice.actions.setOptinPinDetails(optinDetail));
};
export default pinOptFlowSlice.reducer;
