export const fullDateFormat = (dateString: string, locale: string = 'en-Us'): string => {
  const options: Intl.DateTimeFormatOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  const retVal: string = new Date(dateString).toLocaleDateString(locale, options);

  return retVal;
};
export const isDateToday = (date: Date): boolean => {
  let today = new Date();
  return datesEqual(date, today);
};

export const datesEqual = (date1: Date, date2: Date): boolean => {
  return (
    date1.getDate() === date2.getDate() &&
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth()
  );
};
export const validateDateBetweenTwoDates = (fromDate: Date, toDate: Date, givenDate: Date) => {
  return givenDate <= toDate && givenDate >= fromDate;
};

export const stripTime = (date: Date): Date => {
  return new Date(`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`);
};
export const dateArrayIncludes = (date: Date, dateArray: Date[]): boolean => {
  return validateDateBetweenTwoDates(dateArray[0], dateArray[dateArray.length - 1], date);
};
export const dateArrayContains = (date: Date, dateArray: Date[]): boolean => {
  return dateArray.filter((e) => datesEqual(e, date)).length > 0;
};
export const getDaysInMonth = (year: number, month: number): Date[] => {
  var date = new Date(year, month, 1);
  var days: Date[] = [];
  while (date.getMonth() === month) {
    days.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }
  return days;
};

export const getWeeks = (year: number, month: number): Date[][] => {
  let days = getDaysInMonth(year, month);
  let weeks: Date[][] = [];
  let weekDays: Date[] = [];
  days.forEach((day: Date, index: number) => {
    weekDays.push(day);
    if (day.getDay() === 6 || index === days.length - 1) {
      weeks.push(weekDays);
      weekDays = [];
    }
  });

  return weeks;
};

export const getDateStamp = (date: Date): string => {
  return `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`;
};
export const setDateFormat = (date: Date): string => {
  var options: Intl.DateTimeFormatOptions = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
  return date.toLocaleDateString('en-US', options);
};

export const addDays = (numDays: number): Date => {
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + numDays);
  return currentDate;
};

export const addDaysGivenDate = (checkout: Date, numDays: number): Date => {
  const currentDate = new Date(checkout.getFullYear(), checkout.getMonth(), checkout.getDate());
  currentDate.setDate(checkout.getDate() + numDays);
  return currentDate;
};

export const isPrevDate = (date: Date): boolean => {
  const now = new Date();
  date.setHours(0, 0, 0, 0);
  now.setHours(0, 0, 0, 0);
  return date < now;
};
export const convertStringToDate = (input: string): Date => {
  if (input) {
    const dateComponent = input.split('-');
    const retDate: Date = new Date(
      parseInt(dateComponent[0], 10),
      parseInt(dateComponent[1], 10) - 1,
      parseInt(dateComponent[2], 10),
    );
    return retDate;
  }
  return new Date();
};
const _MS_PER_DAY = 1000 * 60 * 60 * 24;

// a and b are javascript Date objects
export const dateDiffInDays = (startDate: Date, endDate: Date): number => {
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
  const utc2 = Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());

  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
};

export const getDateString = (day: Date): string => {
  const offset = day.getTimezoneOffset();
  day = new Date(day.getTime() - offset * 60 * 1000);
  const dateString: string = day.toISOString().split('T')[0];
  return dateString;
};
export const getMonthEndDate = (month, year) => {
  const d = new Date(year, month + 1, 0);
  const numDays = d.getDate();

  const retVal = new Date(year, month, numDays);
  const retMonth = retVal.getMonth() + 1 > 9 ? retVal.getMonth() + 1 : `0${retVal.getMonth() + 1}`;
  const retDate = retVal.getDate() > 9 ? retVal.getDate() : `0${retVal.getDate()}`;
  const dateString = `${retVal.getFullYear()}-${retMonth}-${retDate}`;
  return dateString;
};
export const getMonthStartDate = (month, year) => {
  const retVal = new Date(year, month, 1);

  const retMonth = retVal.getMonth() + 1 > 9 ? retVal.getMonth() + 1 : `0${retVal.getMonth() + 1}`;
  const retDate = retVal.getDate() > 9 ? retVal.getDate() : `0${retVal.getDate()}`;
  const dateString = `${retVal.getFullYear()}-${retMonth}-${retDate}`;

  return dateString;
};
export const getMonthsBetweenDates = (checkInDate: Date, checkOutDate: Date): number => {
  let months = (checkOutDate.getFullYear() - checkInDate.getFullYear()) * 12;
  months -= checkInDate.getMonth();
  months += checkOutDate.getMonth();
  months = months <= 0 ? 0 : months;
  return months;
};
export const createMonthList = (
  startDate: Date,
  endDate: Date,
  months: number,
  localizedMonthNames: string[],
  showSingleMonths: boolean,
) => {
  const monthList: string[] = [];
  if (months === 0) {
    const monthName = localizedMonthNames[startDate.getMonth()];
    const year = startDate.getFullYear();
    monthList.push(`${monthName} ${year}`);
  } else {
    for (let index = 1; index <= months; index++) {
      if (index === 1) {
        const monthName = localizedMonthNames[startDate.getMonth()];
        const year = startDate.getFullYear();
        monthList.push(`${monthName} ${year}`);
      }
      if (index === months) {
        const monthName = localizedMonthNames[endDate.getMonth()];
        const year = endDate.getFullYear();
        monthList.push(`${monthName} ${year}`);
      } else {
        const checkInMonth = startDate.getMonth();
        const checkInYear = startDate.getFullYear();
        const calculatedMonth = (checkInMonth + index) % 12;
        const monthString = localizedMonthNames[calculatedMonth];
        const calculatedYear = checkInMonth + index > 11 ? checkInYear + 1 : checkInYear;
        monthList.push(`${monthString} ${calculatedYear}`);
      }
    }
  }
  if (!showSingleMonths) {
    const formattedMonthList = monthList.map((item, index) => {
      const endValue = index + 1 === monthList.length ? '' : monthList[index + 1];
      return `${item} - ${endValue}`;
    });
    formattedMonthList.pop();
    return formattedMonthList;
  } else {
    return monthList;
  }
};
export const formatDate = (datetime: any) => {
  const date: any = new Date(datetime);
  return date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
};
export const formatPackageDate = (dateString: any) => {
  if (dateString) {
    const date = new Date(dateString);
    const month = date.toLocaleString('default', { month: 'long' });

    const day = date.getDate();
    const year = date.getFullYear();
    return `${month} ${day}, ${year}`;
  } else {
    return '';
  }
};
export const formatTravelDate = (dateString: any) => {
  if (dateString) {
    let date = new Date(dateString);
    let formattedDate: any = date?.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });

    // Return formatted date
    return formattedDate;
  } else {
    return '';
  }
};
