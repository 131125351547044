import React, { FC, ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setNewPaymentcheckBox } from '../../../features/newOfferPayment/NewOfferPaymentSlice';
import withLocalization from '../../hoc/withLocalization';
import { RootState } from '../../../redux/rootReducer';
import ParseHtml from '../../common/ParseHtml';
import './PaymentDetailsPage.scss';

const TermsAndConditionPage: FC<any> = (props: any): ReactElement => {
  const { localize } = props;
  const isPaymentCheck = useSelector((state: RootState) => state.newOfferPaymentDetails.isPaymentCheckBox);
  const locOfferDetails: any = useSelector((state: RootState) => state.newOfferDetails.newOfferData);
  const isPinOptFlow: boolean = useSelector((state: RootState) => state.pinOptFlowDetails.pinOptFLow);
  const pinOfferDetails: any = useSelector((state: RootState) => state.offerDetails.pinOptOfferDetails);
  const finalOfferData: any = isPinOptFlow ? pinOfferDetails : locOfferDetails;
  const offerData: any = (finalOfferData?.offers && finalOfferData?.offers[0]) || '';
  const tourType = offerData?.tourTp || '';
  const isTr: boolean = tourType && tourType.toUpperCase() === 'TR' ? true : false;
  const dispatch = useDispatch();
  const handleCheckboxClick = () => {
    dispatch(setNewPaymentcheckBox(!isPaymentCheck));
  };
  const handleCheckboxChange = (evt) => {};

  const handleKeyDown = () => {};
  return (
    <>
      <div
        className="checkRowContainer-payment"
        onKeyDown={handleKeyDown}
        arial-label={'check-payment'}
        role="button"
        tabIndex={0}
        onClick={handleCheckboxClick}
      >
        <input
          type="checkbox"
          checked={isPaymentCheck}
          id="checkBox-billing"
          onChange={handleCheckboxChange}
          name="checkbox"
          className="payment-CheckBox"
          aria-label="billing-checkBox"
        />
        <span className="payment-Check-Desc">
          {isTr ? (
            <ParseHtml
              htmlInput={localize(`preview-sales-engine.static-content.copy.pbeo.payment-form-acknowledgement`)}
            ></ParseHtml>
          ) : (
            <ParseHtml
              htmlInput={localize(`preview-sales-engine.static-content.copy.pbeo.payment-form-acknowledgement-tnr`)}
            ></ParseHtml>
          )}
        </span>
      </div>
    </>
  );
};

export default withLocalization(TermsAndConditionPage);
