import React, { FC, useEffect } from 'react';
import { withRouter } from 'react-router';
import { useSelector } from 'react-redux';
import withLocalization from '../hoc/withLocalization';
import { useDispatch } from 'react-redux';
import { RootState } from '../../redux/rootReducer';
import { loadOfferDetails } from '../../features/offerSearch/offerSearchSlice';
import {
  setPinOptFlowLeadInfo,
  setLeadInfoCheckBox,
  setOfferFlowDetails,
  addOptinPinDetailsRequest,
} from '../../features/PinOptLeadInfoContainer/PinOptFlowOfferSlice';
import { logAnalyticData } from '../../analytics';
import { Offer, OfferResponse } from '../../features/offerSearch/types';
import './LeadInfoDetails.scss';
import { generateCorrelationId } from '../../util/CommonLogic';

interface SearchPanelProps {
  localize?: any;
  history: any;
  isValidForm: boolean;
  isEdit: boolean;
  email?: any;
  phone?: any;
  ischecked: boolean;
}
const LeadSubmitBtn: FC<SearchPanelProps> = (props: SearchPanelProps) => {
  const { localize, history, isValidForm, isEdit, email, phone, ischecked } = props;
  const dispatch = useDispatch();
  const pinInfoDetails: any = useSelector((state: RootState) => state.pinOptFlowDetails.pinDetails);
  // const allDetails: any = useSelector((state: RootState) => state.pinOptFlowDetails);
  const offerDetails: OfferResponse = useSelector((state: RootState) => state.offerDetails.pinOptOfferDetails);
  const isSubmitErr = useSelector((state: RootState) => state.offerDetails.hasError);
  const submitError = useSelector((state: RootState) => (isSubmitErr ? state.offerDetails.error?.errorMessageKey : ''));
  const callCenterPhone: any = useSelector((state: RootState) => state.offerDetails.nonFormatedCallcenterNo);
  const offer: Offer = (offerDetails?.offers && offerDetails.offers[0]) || '';
  const offerCode = offer?.offerCode || 'Vacation Offer';
  const errPhNo = { errPhNo: callCenterPhone || '' };
  const offerType = () => {
    const showCalendar = offer?.showCalendar;
    switch (showCalendar) {
      case 'H':
        return 'Open-Dated';
      case 'S':
        return 'Dated';
      default:
        return 'Open-Dated';
    }
  };

  useEffect(() => {
    if (offerDetails?.offers && offerDetails?.offers?.length > 0) {
      const getUrlSearchParam = sessionStorage.getItem('pinOptUrlSearchParam') || '';
      history.push('/offer-details' + getUrlSearchParam);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offerDetails]);

  const handleClickBackToLandingPage = () => {
    history.push('/pin-error', errPhNo);
    window.dataLayer.push({
      event: 'wrong-info',
    });
    logAnalyticData(
      {
        event: 'pbePinWrongInformation',
        siteSection: 'MVC Optimized PBE',
        pageName: 'MVC PBE Verify Page',
        pageHierarchy: '',
        server: 'MVC',
        website: 'MVC react',
        language: 'en',
      },
      {
        errorMessage: submitError,
        offerCode: offerCode,
        offerType: offerType(),
        flowldentifier: 'PIN',
        bookingStepName: 'MVC I PBE Verify Page',
      },
      {
        linkName: 'PBE PIN Wrong Information',
        linkClickPage: 'MVC PBE Verify Page',
      },
    );
  };
  useEffect(() => {
    if (isSubmitErr) {
      history.push('/pin-error', errPhNo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitErr]);

  const optinDetailMapping = () => {
    const chkFlag = ischecked ? 'Y' : 'N';
    const mapData: any = {
      pin: pinInfoDetails?.pin || '',
      optInMail: chkFlag,
      optInEmail: chkFlag,
      optInText: chkFlag,
      optInPhone: chkFlag,
      customerEmail: email || '',
      customerPhone: phone || '',
      customerAddress: '',
      customerCity: '',
      customerState: '',
      customerCountry: '',
      customerZipcode: '',
    };
    return mapData;
  };
  const handleClickLeadSubmitBtn = () => {
    if (isValidForm) {
      const correlationId = generateCorrelationId();
      const optinPayload = optinDetailMapping();
      dispatch(addOptinPinDetailsRequest({ ...optinPayload, correlationId }, correlationId));
      if (isEdit) {
        dispatch(setPinOptFlowLeadInfo({ email, phone }));
      }
      dispatch(setOfferFlowDetails(true));
      dispatch(setLeadInfoCheckBox(ischecked));
      dispatch(loadOfferDetails(pinInfoDetails?.pin, pinInfoDetails?.lastName, 'y', true));
      window.dataLayer.push({
        event: 'pin-verify',
      });
      logAnalyticData(
        {
          event: 'pbePinVerify',
          siteSection: 'MVC Optimized PBE',
          pageName: 'MVC PBE Verify Page',
          pageHierarchy: '',
          server: 'MVC',
          website: 'MVC react',
          language: 'en',
        },
        {
          errorMessage: submitError,
          offerCode: offerCode,
          offerType: offerType(),
          flowldentifier: 'PIN',
          bookingStepName: 'MVC I PBE Verify Page',
        },
        {
          linkName: 'PBE PIN Verify',
          linkClickPage: 'MVC PBE Verify Page',
        },
      );
    }
  };

  return (
    <>
      <div className="leadInfo-btn-container">
        <button
          className={isValidForm ? 'myInfo-btn-enable' : 'myInfo-btn-disable'}
          onClick={() => handleClickLeadSubmitBtn()}
        >
          {localize('preview-sales-engine.static-content.copy.pbeo.yes-my-info-cta-label')}
        </button>
        <button className="notMyInfo-btn" onClick={() => handleClickBackToLandingPage()}>
          {localize('preview-sales-engine.static-content.copy.pbeo.not-my-info-cta-label')}
        </button>
      </div>
    </>
  );
};
export default withRouter(withLocalization(LeadSubmitBtn));
